<template>
  <div
    v-if="backgroundpage"
    class="auth-layout"
    v-bind:style="{ background: `url(${backgroundpage})` }"
  >
    <div class="auth-form">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
  <div v-else class="auth-layout">
    <div class="auth-form">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>
import { Constants } from "../../utils/constants";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Layout",
  data() {
    return {
      dataShop: [],
      backgroundpage: null,
      homepage: window.location.origin,
    };
  },
  created() {
    let request = {
      domain: Constants.DOMAIN,
      shop_id: this.$route.params.shopId || null,
    };
    this.$store.dispatch("getShopById", request);
    this.getListSettingShopUser(request);
  },
  computed: {
    ...mapGetters(["ListSettingShop"]),
  },
  watch: {
    ListSettingShop() {
      this.dataShop = this.ListSettingShop;
      if (this.dataShop.length > 0) {
        if (this.dataShop[0].background != null) {
          this.backgroundpage =
            process.env.VUE_APP_ROOT_BACKEND +
            "/" +
            this.dataShop[0].background;
          this.backgroundpage = this.backgroundpage.replace(/ /g, "%20");
        }
      }
    },
  },
  methods: {
    ...mapActions({ getListSettingShopUser: "getListSettingShopUser" }),
  },
};
</script>
<style lang="scss">
// Import Main styles for this application
@import "../../views/auth/auth.scss";
</style>
